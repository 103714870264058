import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
import { latLng, Icon, icon } from "leaflet";
import shadowUrl from "@/assets/images/logo/shadow.png";

Vue.use(Vuex);
Vue.use(VueAxios, axios);

let mergeDuplicatesByIds = (ar, a) =>
  ar.reduce((r, c) => {
    let d = r.findIndex((x) => x[a] === c[a]);
    return d >= 0 ? [...r.filter((f) => f[a] !== c[a]), { ...c }] : [...r, c];
  }, []);

export default new Vuex.Store({
  state: () => ({
    categories: null,
    activeCategories: [],
    gifts: [],
    giftsPagination: {},
    gifts1: null,
    user: false,
    userGifts: null,
    loyaltyCards: null,
    transactions: null,
    history: null,
    systemNotifications: null,
    userNotifications: {},
    companyStores: null,
  }),
  getters: {
    categories: (state) => state.categories,
    activeCategories: (state) => state.activeCategories,
    gifts: (state) => state.gifts,
    gifts1: (state) => state.gifts1,
    userGifts: (state) => state.userGifts,
    user: (state) => state.user,
    loyaltyCards: (state) => state.loyaltyCards,
    transactions: (state) => state.transactions,
    history: (state) => state.history,
    systemNotifications: (state) => state.systemNotifications,
    userNotifications: (state) => state.userNotifications,
    companyStores: (state) => state.companyStores,
    giftsPagination: (state) => state.giftsPagination,
  },
  mutations: {
    setCategories(state, payload) {
      state.categories = payload;
    },
    setActiveCategories(state, payload) {
      state.activeCategories = payload;
    },
    setGifts(state, payload) {
      state.gifts = payload;
    },
    setGifts1(state, payload) {
      state.gifts1 = payload;
    },
    setGiftsPagination(state, payload) {
      state.giftsPagination = payload;
    },
    setUserGifts(state, payload) {
      state.userGifts = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setSystemNotifications(state, payload) {
      state.systemNotifications = payload;
    },
    setUserNotifications(state, payload) {
      state.userNotifications = payload;
    },
    setLoyaltyCards(state, payload) {
      // state.loyaltyCards = payload
      // const keys_to_keep = ['logo', 'card_number', 'name', 'showFront'];
      // const result = payload.map(e => {
      //     const obj = {};
      //     keys_to_keep.forEach(k => obj[k] = e[k])
      //     return obj;
      //   });
      // const resultArray = result.map(elm =>
      //     ({ image: elm.logo, cardNumber: elm.card_number, cardCode: 'XXX', showFront: elm.showFront }));
      // state.loyaltyCards = resultArray;
      state.loyaltyCards = payload;
    },
    setHistory(state, payload) {
      state.history = payload;
    },
    setTransactions(state, payload) {
      // const keys_to_keep = ['transaction_type_name', 'total_price', 'total_points', 'created_at', 'store', 'transaction_type_id'];
      // const result = payload.map(e => {
      //     const obj = {};
      //     keys_to_keep.forEach(k => obj[k] = e[k])
      //     return obj;
      //   });
      // const resultArray = result.map(elm =>
      //     ({ Logo: elm.store.logo, name: elm.store.name, total_price: [2,3,7,8,9,10,11].includes(elm.transaction_type_id) ? elm.total_price : '-', points: elm.total_points, Ημερομηνία: moment(String(elm.created_at)).format('DD/MM/YYYY, hh:mm')}));
      // state.transactions = resultArray;
      state.transactions = payload;
    },
    setCompanyStores(state, payload) {
      const keys_to_keep = [
        "id",
        "map_logo",
        "coordinates",
        "name",
        "address",
        "primary_phone",
      ];
      const result = payload.map((e) => {
        const obj = {};
        keys_to_keep.forEach((k) => (obj[k] = e[k]));
        return obj;
      });
      const resultArray = result.map((elm) => ({
        id: elm.id,
        map_logo: icon(
          Object.assign({}, Icon.Default.prototype.options, { iconUrl: elm.map_logo, shadowUrl })
        ),
        latlng: latLng(elm.coordinates.lat, elm.coordinates.lon),
        text: elm.name + "<br>" + elm.address + "<br>" + elm.primary_phone,
      }));
      state.companyStores = resultArray;
      console.log(payload);
    },
  },
  actions: {
    async getCategories({ commit }) {
      let response = await axios.get(
        "external/company/catalog/gift-categories"
      );
      commit("setCategories", response.data.data);
    },
    async getSystemNotifications({ commit }) {
      let response = await axios.get("common/system-notifications");
      commit("setSystemNotifications", response.data.data);
    },
    async getUserNotifications({ commit, getters }, page) {
      let response = await axios.get("external/me/notifications?page=" + page);
      if (response.data.status === "success" && response.data.data) {
        commit("setUserNotifications", response.data.data);
      }
    },
    async getUserGifts({ commit, getters }, page) {
      let response = await axios.get("external/me/gifts?page=" + page);
      if (response.data.status === "success" && response.data.data) {
        commit("setUserGifts", response.data.data);
      }
    },
    async getLoyaltyCards({ commit, getters }, page) {
      let response = await axios.get("external/me/loyalty-cards?page=" + page);
      if (
        response.data.status === "success" &&
        response.data.data.loyalty_cards
      ) {
        // response.data.data.loyalty_cards.forEach(e => e.showFront = true);
        // let loyaltyCardsWithFront =  response.data.data.loyalty_cards.map(x => { return {...x, ...{ showFront: true } } } );
        response.data.data.loyalty_cards.forEach((e) => {
          e.showFront = true;
          e.card_code = "XXX";
        });
        commit("setLoyaltyCards", response.data.data);
      }
    },
    async getTransactions({ commit, getters }, page) {
      let response = await axios.get("external/me/transactions?page=" + page);
      if (response.data.status === "success" && response.data.data) {
        commit("setTransactions", response.data.data);
      }
    },
    async getHistory({ commit, getters }, page) {
      let response = await axios.get("external/me/notifications?page=" + page);
      console.log(page);
      if (
        response.data.status === "success" &&
        response.data.data.user_notifications
      ) {
        commit("setHistory", response.data.data);
      }
    },
    async getUser({ commit }) {
      try {
        let response = await axios.get("external/me", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        if (response.data.status === "success" && response.data.data) {
          commit("setUser", response.data.data || null);
        }
        return response.data.data;
      } catch (ex) {
        // if(ex.response.data.status === "error"){
        commit("setUser", null);
        // }
      }
    },
    // async getGifts ({ commit }, id) {
    //     let response = await axios.get('external/company/catalog/gifts?gift_category_id='+id)
    //     // commit("setGifts", response.data.data.gifts);
    //     // commit("setGiftsPagination", response.data.data.pagination);

    // },
    async getGifts({ commit, getters }, params) {
      let response = await axios.get(
        "external/company/catalog/gifts?gift_category_id=" +
          params[0] +
          "&page=" +
          params[1]
      );
      // response.data.data.gifts.forEach(e => e.gift_category = id);
      // commit("setGifts", mergeDuplicatesByIds([...response.data.data.gifts, ...getters.gifts], 'gift_id'));
      // commit("setGifts", mergeDuplicatesByIds([...response.data.data.gifts, ...getters.gifts], 'gift_id'));
      commit("setGifts", response.data.data);
      // commit("setGiftsPagination", response.data.data.pagination);
    },
    async getGifts1({ commit }, id) {
      let response = await axios.get(
        "external/company/catalog/gifts?gift_category_id=" + id
      );
      commit("setGifts1", response.data);
      commit("setGiftsPagination", response.data.data.pagination);
    },
    async getCompanyStores({ commit }) {
      let response = await axios.get("external/company/stores");
      // if(response.data.status === "success" && response.data.data.stores){
      commit("setCompanyStores", response.data.data.stores);
      // }
    },
  },
});
