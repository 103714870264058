<template>
  <div>
    <v-navigation-drawer
      class="hidden-md-and-up"
      style="position: fixed"
      v-model="drawer"
      absolute
      temporary
      width="60vw"
    >
      <v-list-item class="pa-2">
        <v-spacer></v-spacer>
        <span large icon @click="drawer = !drawer" v-html="iconSvg(closeIcon)"></span>
      </v-list-item>
      <v-list class="mb--0 pb--0">
        <v-list-item
          :ripple="false"
          to="/home"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Αρχική</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :ripple="false"
          to="home#getPoints"
          link
        >
          <v-list-item-content>
            <v-list-item-title style="color: #004e96 !important">Συλλογή πόντων</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :ripple="false"
          to="home#pointRedemption"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Εξαργύρωση πόντων</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :ripple="false"
          to="/questions-answers"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Q&A</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :ripple="false"
          to="/contact"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Επικοινωνία</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <Login fromHeader = "true" v-if="!user" title="Σύνδεση" />
        <Register style="padding: 15px" fromHeader= "true" v-if="!user" title="Εγγραφή" />
       
      </v-list>
        <v-list>
            <v-list-item v-if="user" @click="mobileList = !mobileList" link :ripple="false" class="headerPoints" text>
                <v-list-item-title style="display: flex"> <img class="mr--15" src="@/assets/images/logo/user_elin.png" width="25px" />  {{ user.full_name ? userName : user.mobile }}
                  <div v-if="mobileList"><v-icon right > mdi-chevron-double-up </v-icon></div>
                  <div v-else><v-icon right > mdi-chevron-double-down </v-icon></div>
                  </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="user && mobileList" link :ripple="false" class="headerPoints" text>
                <v-list-item-title style="display: flex; margin-left:25%"><img class="mr--25" width="25px" slot="logo" src="@/assets/images/logo/up.png" /> {{ user.total_points }} πόντοι </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="user && mobileList" link :ripple="false" class="mt--20" text to="/my-cards">
              <v-list-item-title>Οι κάρτες μου</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="user && mobileList" link :ripple="false" text to="/my-gifts">
              <v-list-item-title>Τα δώρα μου</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="user && mobileList" link :ripple="false" text to="/history">
              <v-list-item-title>Το ιστορικό μου</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="user && mobileList" link :ripple="false" text to="/profile">
              <v-list-item-title>Προφίλ</v-list-item-title>
            </v-list-item>
             <v-list-item v-if="user && mobileList" link :ripple="false" text to="/change-password">
              <v-list-item-title>Αλλαγή κωδικού</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="user && mobileList" link :ripple="false" text @click="logout" href="javascript:void(0)">
              <v-list-item-title>Αποσύνδεση</v-list-item-title>
            </v-list-item>
            
          </v-list>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->
  <scroll-fixed-header :fixed.sync="fixed" :threshold="56">

    <v-app-bar
      dark
      elevation="0"
      color="transparent"
      absolute
      class="default-header"
    >
      <router-link to="/" class="logo">
        <slot name="logo"></slot>
      </router-link>
      <!-- End brand logo -->
      <v-spacer></v-spacer>
      <v-btn
        icon
        class="ma-0 pa-0 hidden-md-and-up"
        @click="drawer = !drawer"
        v-html="iconSvg(icon)"
      >
      </v-btn>
      <!-- End mobile menu icon -->
      <v-toolbar-items class="hidden-xs-only hidden-sm-only">
          <v-btn :ripple="false" text 
              to="/home">Αρχική</v-btn>
          <v-btn :ripple="false" text class="noBorder"
              to="/home#getPoints">Συλλογή πόντων</v-btn>
          <v-btn :ripple="false" text to="home#pointRedemption">Εξαργύρωση πόντων</v-btn>
          <v-btn v-if="!user" :ripple="false" text to="/questions-answers">Q&A</v-btn>
          <v-btn v-if="user" :ripple="false" text to="/news">Τα νέα μας</v-btn>
          <v-btn v-if="user" :ripple="false" text to="/promo-codes">PromoCodes</v-btn>
          <v-btn :ripple="false" text to="/contact">Επικοινωνία</v-btn>
          <Login v-if="!user" title="Σύνδεση" icon="mdi-plus" />
          <Register v-if="!user" title="Εγγραφή" icon="mdi-plus" />
          <v-menu
            v-if="user"
            open-on-hover
            bottom
            min-width="240"
            min-height="240"
            offset-y
            transition="scroll-y-reverse-transition"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn :ripple="false" text v-bind="attrs" v-on="on">
             <div style="display: flex"><img class="mr--15" src="@/assets/images/logo/user_elin.png" width="25px" />  {{ user.full_name ? userName : user.mobile }} </div>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-if="user" link :ripple="false" class="headerPoints" text>
                <v-list-item-title style="display: flex"> <img class="mr--25" width="25px" slot="logo" src="@/assets/images/logo/up.png" /> {{ user.total_points }} πόντοι </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="user" link :ripple="false" class="mt--20" text to="/my-cards">
              <v-list-item-title>Οι κάρτες μου</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="user" link :ripple="false" text to="/my-gifts">
              <v-list-item-title>Τα δώρα μου</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="user" link :ripple="false" text to="/history">
              <v-list-item-title>Το ιστορικό μου</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="user" link :ripple="false" text to="/profile">
              <v-list-item-title>Προφίλ</v-list-item-title>
            </v-list-item>
             <v-list-item v-if="user" link :ripple="false" text to="/change-password">
              <v-list-item-title>Αλλαγή κωδικού</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="user" link :ripple="false" text @click="logout" href="javascript:void(0)">
              <v-list-item-title>Αποσύνδεση</v-list-item-title>
            </v-list-item>
            
          </v-list>
        </v-menu>
          <!-- End Services dropdown -->

      </v-toolbar-items>
      <!-- End header menu item -->
    </v-app-bar>
      </scroll-fixed-header>

    <!-- End top header navbar -->
  </div>
</template>

<script>
  import feather from "feather-icons";
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from "vuex";
  import Login from "@/views/Login";
  import Register from "@/views/Register";

  export default {
    name: 'Header',
    components: {
      Login,
      Register,
    },
    computed: {
      ...mapGetters (["user"]),
      underlined_home(){
        if(this.$router.currentRoute.hash === '#getPoints'){
          return true;
        }
        return false;
      },
      underlined_home_points(){
        if(this.$router.currentRoute.path === '/home' && this.$router.currentRoute.hash != '#getPoints'){
          console.log('ooo');
          return true;
        }
        return false;
      },
      userName(){
        let user_name = this.user.full_name.split(" ");
        return user_name[0] ? user_name[0] : this.user.full_name;
      },

    },
    async  created () {
        await this.getUser();
    },
     methods: {
      ...mapActions(["getUser"]),
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
      logout(){
        localStorage.removeItem('token');
        // var paths = ["/promo-codes", "/my-cards", "/my-gifts", "/profile", "/history","/change-password"];
        // paths.includes(this.$router.currentRoute.path) ? this.$router.push('/home') : this.$router.go(this.$router.currentRoute) ;

        var paths = ["/", "/home"];
        paths.includes(this.$router.currentRoute.path) ? this.$router.go(this.$router.currentRoute) : this.$router.push('/') ;
      }
    },
    data: () => ({
      fixed: false,
      drawer: false,
      mobileList: false,
      items: [
        { title: "About", to: "/about" },
        { title: "Contact", to: "/contact" },
      ],
      HomeVerisiondropDownItems: [
        { title: "Οι κάρτες μου", to: "/my-cards" },
        { title: "Τα δώρα μου", to: "/my-gifts" },
        { title: "Το ιστορικό μου", to: "/history" },
        { title: "Προφίλ", to: "/profile" },
        { title: "Αποσύνδεση", to: "/logout" },

      ],
      icon: "menu",
      closeIcon: "x",
    }),

   
  };
</script>

<style lang="scss">
  .feather-menu {
    color: #134075;
  }
  .headerPoints {
    border-bottom: 1px solid lightgrey;
    text-align:center; 
  }
  .scroll-fixed-header.is-fixed > header {
    background-color: white !important;
    max-height: 120px;
  }
  
  @media only screen and (min-width: 960px) and (max-width: 1665px) {
    .logo{
      position: absolute;
      left: 40px;
    }
  }

 @media only screen and (min-width: 960px) and (max-width: 1155px) {
    .v-toolbar:not(.tab-toolbar) .v-btn.v-btn.v-size--default:not(.btn-move) {
      font-size: 14px !important;
      margin: 0 11px !important;
    }
  }
</style>
