import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueParticles from "vue-particles";
import CoolLightBox from 'vue-cool-lightbox';
import VueScrollactive from 'vue-scrollactive';
import * as VueGoogleMaps from 'vue2-google-maps'
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/main.scss'
import './axios'
import store from './store/index'
import FlashMessage from "@smartweb/vue-flash-message";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueLoading from 'vuejs-loading-plugin'
import Vuex from 'vuex'
import VueCountryCode from "vue-country-code-select";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './app.scss'
import ScrollFixedHeader from 'vuejs-scroll-fixed-header';
import pdf from 'vue-pdf'
Vue.component('vue-pdf', pdf)
Vue.use(ScrollFixedHeader);

Vue.use(FlashMessage);
Vue.use(VueCountryCode);
Vue.use(Vuex)
Vue.use(VueLoading, {
    loader: "./assets/images/logo/logo.png",
    dark: false, // default false
    text: 'Loading', // default 'Loading'
    loading: false, // default false
    backgroundColor: 'red',
  }
  )
// Vue.use(VueLoading, {
//   dark: true, // default false
//   text: 'Loading', // default 'Loading'
//   loading: true, // default false
//   loader: "./assets/images/logo/logo.png",
//   customLoader: './assets/images/logo/logo.png', // replaces the spinner and text with your own
//   background: 'rgb(255,255,255)', // set custom background
//   classes: ['myclass'] // array, object or string
// })
Vue.use(VueSweetalert2);
Vue.config.productionTip = false
Vue.use(VueParticles)
Vue.use(CoolLightBox)
Vue.use(VueScrollactive);
Vue.use(BootstrapVue)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBO7NVvj3D2unctftPpj-O0n3aoS0MbUEQ',
        libraries: 'places', // This is required if you use the Autocomplete plugin
    },

})
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
