<template>
    <v-dialog
      v-model="dialog"
      persistent
      width="auto "
      @click:outside="dialog = false"
    >
    <template v-slot:activator="{ on, attrs }">
        <v-btn style="background: white; color: #004e96" v-if="fromHeader === 'true'" :ripple="false" v-bind="attrs" v-on="on" text >Εγγραφή</v-btn>
        <v-btn v-else-if="notHeader === 'true'" rounded class="radius-btn button_beautify" dark  :ripple="false" v-bind="attrs" v-on="on" text  >  Εγγραφή </v-btn>
        <v-btn v-else :ripple="false" v-bind="attrs" v-on="on" text >Εγγραφή</v-btn>
      </template>
      <v-card>
      <v-form ref="form"
        v-model="valid"
        lazy-validation>
        <v-card-title class="pb-0">
        <div class="d-block caption text-center">
                      <v-subheader> <h3>Εγγραφή</h3></v-subheader>
                        </div>
                  </v-card-title>
          <v-card-title class="pb-0">
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-img width="250px" class="mx-auto mt--20" src="@/assets/images/brand/frontCard.png" ></v-img>
              <v-row>
                <v-card-text class="pb-0 mt--40 mb--30">
                      <v-subheader> <h5 style="text-align: center;">Κάνε εγγραφή στο ελίν up επιβράβευση χρησιμοποιώντας την κάρτα σου ή δημιούργησε καινούργια.</h5></v-subheader>
                  </v-card-text>
              <v-col cols="12">   
                  <v-radio-group
                    v-model="addCard"
                    row
                  >
                    <v-radio
                      label="Καταχώρηση κάρτας"
                      value="generate_card"
                    ></v-radio>
                    <v-radio
                      label="Έκδοση νέας κάρτας"
                      value="assign_card"
                    ></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col v-if="userExists"
                  cols="2"
                  sm="2"
                  md="2"
                ></v-col>
                <v-col v-if="userExists"
                  cols="10"
                  sm="10"
                  md="10"
                >
                <p style="color:red">Ο χρήστης είναι ήδη εγγεγραμμένος</p>
                </v-col>
              <v-col
                  cols="2"
                  sm="2"
                  md="2"
                >
                   <vue-country-code
                    v-model="country_id"
                    @onSelect="onSelect"
                    :preferredCountries="['gr', 'it', 'us']">
                </vue-country-code>
                </v-col>
                <v-col
                  cols="10"
                  sm="10"
                  md="10"
                >
                 <v-text-field
                    label="Κινητό τηλέφωνο*"
                    hint="Κινητό τηλέφωνο"
                    v-model="mobile"
                    :rules="[rules.required,rules.integerOnly, rules.phoneRules]"
                    persistent-hint
                    outlined
                    shaped
                  ></v-text-field>
                </v-col>
                <v-col
                  v-show="addCard === 'generate_card'"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    :disabled="userExists"
                    label="Αριθμός κάρτας"
                    v-model="card_number"
                    hint="Αριθμός κάρτας"
                    :rules="[rules.requiredCard, rules.cardRules]"
                    outlined
                    shaped
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  v-show="addCard === 'generate_card'"
                >
                  <v-text-field
                  :disabled="userExists"
                    v-model="card_password"
                    label="Κωδικός κάρτας*"
                    hint="Κωδικός κάρτας"
                    :rules="[rules.requiredCard, rules.cardPasswordRules]"
                    persistent-hint
                    outlined
                  shaped
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                  :disabled="userExists"
                    label="Όνομα"
                    hint="Όνομα"
                    v-model="first_name"
                    :rules="[rules.required]"
                    outlined
                  shaped
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                  :disabled="userExists"
                    label="Επώνυμο*"
                    hint="Επώνυμο"
                    v-model="last_name"
                    persistent-hint
                    :rules="[rules.required]"
                    outlined
                    shaped
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                <v-text-field
                  :disabled="userExists"
                    label="Ημερομηνία Γέννησης"
                    hint="Ημερομηνία Γέννησης"
                    v-model="birth_date"
                    :rules="[rules.required, rules.validDate]"
                    format="D/MMM/YYYY"
                    type="date"
                    outlined
                  shaped
                  ></v-text-field>
                 <!-- <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  :disabled="userExists"
                    v-model="birth_date"
                    label="Ημερομηνία Γέννησης"
                    :rules="[rules.required]"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="birth_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>            -->
                </v-col>
                <v-col cols="12">   
                  <v-radio-group
                    v-model="gender"
                    :disabled="userExists"
                    row
                  >
                    <v-radio
                      label="Άντρας"
                      value="male"
                    ></v-radio>
                    <v-radio
                      label="Γυναίκα"
                      value="female"
                    ></v-radio>
                    <v-radio
                      label="Άλλο"
                      value="other"
                    ></v-radio>
                  </v-radio-group>
              </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                  :disabled="userExists"
                    label="Περιοχή / Πόλη*"
                    hint="Περιοχή / Πόλη"
                    v-model="city"
                    :rules="[rules.required]"
                    persistent-hint
                    outlined
                    shaped
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                 <v-text-field
                 :disabled="userExists"
                    label="Ταχυδρομικός κώδικας*"
                    hint="Ταχυδρομικός κώδικας"
                    :rules="[rules.required, rules.postalCodeRules]"
                    v-model="postal_code"
                    outlined
                    shaped
                  ></v-text-field>
                 
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                  :disabled="userExists"
                    label="Διεύθυνση*"
                    hint="Διεύθυνση"
                    v-model="address"
                    :rules="[rules.required]"
                    persistent-hint
                    outlined
                    shaped
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                  :disabled="userExists"
                    label="Email*"
                    v-model="email"
                    :rules="[rules.required, rules.emailRules]"
                    outlined
                  shaped
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                  :disabled="userExists"
                    label="Κωδικός*"
                    type="password"
                    v-model="password"
                    :rules="[rules.required, rules.length]"
                    outlined
                    shaped
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                  :disabled="userExists"
                    label="Επιβεβαίωση Κωδικού*"
                    type="password"
                    v-model="password_confirmation"
                    :rules="[rules.required, rules.length, passwordConfirmationRule]"
                    outlined
                    shaped
                  ></v-text-field>
                </v-col>
                <v-col cols="12" >
                  <v-checkbox v-model="acceptTerms" 
                  :disabled="userExists"
                  :rules="[rules.requiredTrue]"
                    :true-value="true"
                    :false-value="false">
                    <template v-slot:label>
                      <div>
                        Συμφωνώ με τους 
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a href="/terms" target="_blank"
                              @click.stop
                              v-on="on"> Όρους Χρήσης</a> 
                          </template>
                          Ανοίγει σε νέα καρτέλα
                        </v-tooltip> και την 
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                               <a href="/privacy_policy" target="_blank"
                              @click.stop
                              v-on="on"> Πολιτική Απορρήτου</a>
                          </template>
                          Ανοίγει σε νέα καρτέλα
                        </v-tooltip>.
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                  :disabled="userExists"
                    v-model="acceptMarketing"
                    :label="`Επιθυμώ να λαμβάνω νέα & προσφορές από την ΕΛΙΝΟΙΛ`"
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    :disabled="acceptMarketing == false"
                    v-model="acceptSms"
                    :value="acceptMarketing == false ? false : this.acceptSms"
                    :label="`ΚΙΝΗΤΟ`"
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    :disabled="acceptMarketing == false"
                    v-model="acceptMail"
                    :value="acceptMarketing == false ? false : this.acceptMail"
                    :label="`EMAIL`"
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    :disabled="acceptMarketing == false"
                    v-model="acceptPush"
                    :value="acceptMarketing == false ? false : this.acceptPush"
                    :label="`PUSH`"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
            <small>*Υποχρεωτικά πεδία</small>
          </v-card-text>
          <v-card-actions v-if="!userExists">
            <v-spacer></v-spacer>
            <v-btn
              color="white darken-1"
              text
              @click="dialog = false"
            >
              Κλείσιμο
            </v-btn>
            <v-btn
              color="white darken-1"
              text
              @click="register"
            >
              Εγγραφή
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
</template>


<script>
    import axios from 'axios'
    import { mapMutations, mapActions, mapGetters } from "vuex";
    import moment from 'moment';
    export default {
        components: { moment },
       name: 'Register',
        props: {
        fromHeader: String,
        notHeader: String
      },
       data() {
           return  {
            birth_date: new Date().toISOString().substr(0, 10),
            card_number: "",
            country_id: '',
            card_password: "",
            first_name: "",
            last_name: "",
            birth_date: "",
            gender: "male",
            address: "",
            city: "",
            password1: "",
            postal_code: "",
            mobile: "",
            password: "",
            password_confirmation: "",
            user_notifications: [
              {
              "system_notification_id": 1,
              "value": false
              },
              {
              "system_notification_id": 2,
              "value": false
              },
              {
              "system_notification_id": 3,
              "value": false
              }
            ],
            modal: false,
            menu2: false,
            userExists: false,
            email: "",
            errorTitle: "",
            errorText: "",
            tos: false,
            marketing: false,
            acceptMarketing: false,
            acceptMail: false,
            acceptSms: false,
            acceptPush: false,
            acceptTerms: false,
            time1: null,
            value: null,
            gender: 'male',
            addCard: 'generate_card',
            errors: [],
            valid: true,
            dialog: false,
            mobile: '',
            password: '',
            rules: {
              required: value => !!value || 'Το πεδίο είναι υποχρεωτικό.',
              requiredTrue: v => (v === true) || 'Για να συνεχίσετε, απαραίτητη προϋπόθεση είναι να συμφωνείτε με τους Όρους χρήσης.',
              length: v => (!v || v.length >= 6) || 'Ο κωδικός πρέπει να περιέχει πάνω απο 6 χαρακτήρες.',
              integerOnly: v => /^\d+$/.test(v) || 'Το πεδίο πρέπει να περιέχει μόνο αριθμούς',
              requiredCard: value => (!!value || this.addCard == 'assign_card') || 'Το πεδίο είναι υποχρεωτικό.',
              emailRules: v => (!v || /.+@.+\..+/.test(v)) || 'Το email δεν είναι έγγυρο.',
              postalCodeRules: v => (!v || /^\d{3}\s{0,1}\d{2}$/.test(v)) || 'Ο ταχυδρομικός κώδικας δεν είναι έγγυρος',
              phoneRules: v => (v && (""+v).length == 10) || 'Το κινητό τηλέφωνο πρέπει να αποτελείται απο 10 αριθμούς',
              cardRules: v => (!v || /^\d{5,8}$/.test(v)) || 'Η κάρτα δεν είναι έγγυρη',
              cardPasswordRules: v => (!v || /^\d{3,3}$/.test(v)) || 'Ο κώδικας κάρτας δεν είναι έγγυρος',
              validDate: v => (!v || (moment(new Date(v)) < moment(new Date()).subtract(14, "years") && moment(new Date(v)) > moment(new Date()).subtract(90, "years"))) ||
               'Επιλέξτε μία έγγυρη ημερομηνία γέννησης '
            },
           }
       },
        computed: {
              passwordConfirmationRule() {
                return () => (this.password === this.password_confirmation) || 'Οι κωδικοί δεν ταιριάζουν'
              },
              ...mapGetters (["systemNotifications"]),
              // getStartDate() {
              //   var endDate = new Date().getFullYear();
              //   return endDate
              // },
              // getEndDate() {
              //   var endDate = new Date().getFullYear();
              //   return endDate
              // }
            
        },
        async  created () {
            await this.getSystemNotifications();
        },
        watch: {
          acceptMail: function (newQuestion, oldQuestion) {
            !newQuestion && !this.acceptSms && !this.acceptPush ?
             this.acceptMarketing = false : this.acceptMarketing = true
          },
          acceptSms: function (newQuestion, oldQuestion) {
            !newQuestion && !this.acceptPush && !this.acceptMail ?
             this.acceptMarketing = false : this.acceptMarketing = true
          },
          acceptPush: function (newQuestion, oldQuestion) {
            !newQuestion && !this.acceptSms && !this.acceptMail ?
             this.acceptMarketing = false : this.acceptMarketing = true
          },
          acceptMarketing: function (newQuestion, oldQuestion) {
            if(newQuestion) {
              this.acceptSms = true;
              this.acceptMail = true;
              this.acceptPush = true;
            }else{
              this.acceptSms = false;
              this.acceptMail = false;
              this.acceptPush = false;
            }
          },
          mobile: async function (newQuestion, oldQuestion) {
            if(newQuestion.length === 10){
              const response = await axios.post('external/users/mobile-checker', 
                {
                    mobile: newQuestion,
                }).then(response => {
                  if(response.data.data.exists){
                    this.errorTitle = "Αποτυχία"
                    this.errorText = "Ο χρήστης είναι ήδη εγγεγραμμένος. Παρακαλώ πατήστε σύνδεση και ξέχασα τον κωδικό μου εάν δεν θυμάστε τον κωδικό σας."
                    this.userExists = true;
                    this.$swal({
                      title: this.errorTitle,
                      text: this.errorText,
                      icon: "warning", //built in icons: success, warning, error, info
                      timer: 6000,
                        buttons: {
                          confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                          },
                          cancel: {
                            text: "Cancel",
                            value: false,
                            visible: true,
                            className: "",
                            closeModal: true,
                          }
                        }
                    });
                  }else{
                    this.userExists = false;
                  }

                }).catch(error => {
                    console.log(error)
                });
            }
              
          }
        },
        methods: {
          moment: function (created_at) {
            return moment(String(created_at));
          },
          onSelect({name, iso2, dialCode}) {
              this.country_id = dialCode;
            },
           ...mapActions(["getSystemNotifications"]),

           async register(){
             try{
                if(this.$refs.form.validate()){

                      this.errors = [];
                      let card_add = false;
                      this.addCard === 'generate_card' ? card_add = false : card_add = true;
                      this.$loading(true)

                        const res = await axios.post('external/users/register', 
                        {
                              "generate_card": card_add,
                              "assign_card": !card_add,
                              "card_number": this.card_number || undefined,
                              "card_password": this.card_password || undefined,
                              "first_name": this.first_name,
                              "last_name": this.last_name,
                              "birth_date": this.birth_date,
                              "gender": this.gender,
                              "address": this.address,
                              "city": this.city,
                              "postal_code": this.postal_code,
                              "mobile": this.mobile,
                              "password": this.password,
                              "password_confirmation": this.password_confirmation,
                              "county_id": this.country_id,
                              "email": this.email,
                              "tos": true,
                              "marketing": Boolean(this.acceptMarketing),
                              "user_notifications": [
                                {
                                  "system_notification_id": parseInt(this.systemNotifications.find( item => item.name == "mail").system_notification_id),
                                  "value": Boolean(this.acceptMail)
                                },
                                {
                                  "system_notification_id": parseInt(this.systemNotifications.find( item => item.name == "sms/viber").system_notification_id),
                                  "value": Boolean(this.acceptSms)
                                },
                                {
                                  "system_notification_id": parseInt(this.systemNotifications.find( item => item.name == "push").system_notification_id),
                                  "value": Boolean(this.acceptPush)
                                }
                              ]
                        }).catch(error => {
                          console.log(error)

                            this.$loading(false)
                            if(error.response.data.message === 'Wrong loyalty card credentials provided.'){
                              this.errorTitle = "Η κάρτα δεν υπάρχει"
                              this.errorText = "Η κάρτα που προσπαθείτε να προσθέσετε δεν υπάρχει. Παρακαλώ προσθέσετε τη σωστή κάρτα ή επιλέξτε έκδοση νέας κάρτας."
                            }else if(error.response.data.message === 'Already exist.'){
                              this.errorTitle = "Αποτυχία"
                              this.errorText = "Ο χρήστης είναι ήδη εγγεγραμμένος. Παρακαλώ πατήστε σύνδεση και ξέχασα τον κωδικό μου εάν δεν θυμάστε τον κωδικό σας."
                            }else{
                              this.errorTitle = "Αποτυχία"
                              this.errorText = "Κάτι πήγε στραβά. Παρακαλώ ξαναπροσπαθήστε."
                            }
                            this.$swal({
                              title: this.errorTitle,
                              text: this.errorText,
                              icon: "warning", //built in icons: success, warning, error, info
                              timer: 6000,
                                buttons: {
                                  confirm: {
                                    text: "OK",
                                    value: true,
                                    visible: true,
                                    className: "",
                                    closeModal: true
                                  },
                                  cancel: {
                                    text: "Cancel",
                                    value: false,
                                    visible: true,
                                    className: "",
                                    closeModal: true,
                                  }
                                }
                            });
                        });

                        await axios.post('external/users/login', 
                              {
                                  mobile: this.mobile,
                                  password: this.password,
                              }).then(response => {
                                  this.$loading(false)
                                  localStorage.setItem('token', response.data.access_token); 
                                  var paths = ["/", "/home"];
                                  paths.includes(this.$router.currentRoute.path) ? this.$router.go(this.$router.currentRoute) : this.$router.push('/home') ;
                              }).catch(error => {
                                  this.$loading(false)
                                  if (error.response.data.code == 401) {
                                      this.errors = "Παρακαλώ ελέγξτε τα στοιχεία σας.";
                                  } 
                              });
                              this.$refs.form.reset();
                  
                }else{
                  this.$loading(false)
                  return false;
                }            
              }catch(e){
                this.$loading(false)
                console.log(e)
                  this.error = e.message;
                  return e;
              }}
       },
    };
</script>

<style lang="scss">
  .button_beautify {
    padding: 30px 43px !important;
    border-radius: 30px !important;
    background: #19b3e6 !important;
    animation-delay: 1s;
    transition: background-color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .button_beautify:hover {
    background-color: #104788 !important;
  }
</style>
