<template>
  <!-- Start Footer Style Two  -->
  <div
    class="footer-style-3 ptb--100"
  >
      <div class="container-lg">
          <v-row align="center">
              <v-col lg="3" md="3" sm="12" cols="12">
                  <div class="inner text-left mb--50">
                      <router-link to="/">
                              <img :src="logo" alt="Logo images" />
                          </router-link>
                      <div class="logo text-center text-sm-left">
                          
                      </div>
                      <p class="mt--20"> To πρωτοποριακό πρόγραμμα επιβράβευσης της ελίν σε ανταμείβει με πλούσια δώρα</p>
                  </div>
              </v-col>
              <!-- End .col -->
              <v-col lg="3" md="3" sm="12" cols="12"  class=" mb--30 ml_sm--30 mt_sm--20">

                  <div class="inner text-left">
                      <h4 class="pb--20">Σύνδεσμοι</h4>
                      <ul class="rn-lg-size justify-center liststyle">
                          <li v-for="(social, i) in socialListText" :key="i">
                              <a :href="social.url">
                                  <p class="footerStyle pb--10"> {{ social.text }}</p>
                              </a>
                          </li>
                      </ul>
                  </div>
              </v-col>
              <!-- End .col -->
              <v-col lg="3" md="3" sm="12" cols="12"  class=" mb--30 ml_sm--30 mt_sm--20">
                  <div class="inner text-left">
                      <h4 class="pb--20">Υποστήριξη</h4>

                      <ul class="rn-lg-size justify-center liststyle">
                          <li v-for="(social, i) in socialListHelp" :key="i">
                            <p class="footerStyle pb--10 parPadding"><i :class="social.class"> </i> <span class="pl--5"> {{ social.text }} </span></p>
                          </li>
                      </ul>
                      
                  </div>
              </v-col>
              <v-col lg="3" md="3" sm="12" cols="12"  class=" mb--30 ml_sm--30 mt_sm--20">
                  <div class="inner text-left">
                      <h4 class="pb--20">Social Media</h4>
                      <ul class="rn-lg-size justify-center liststyle">
                          <li v-for="(social, i) in socialListHelp1" :key="i">
                              <a :href="social.url">
                                  <p class="footerStyle pb--4"><i :class="social.class"> </i><span class="pl--5"> {{ social.text }} </span></p>
                              </a>
                          </li>
                      </ul>
                  </div>
              </v-col>
              <!-- End .col -->
          </v-row>

          <!-- <v-card
            class="d-flex justify-space-around mb-6 mt--100"
            flat
            tile
            >
             <p>Copyright © {{ new Date().getFullYear() }} RoadCube. All Rights Reserved.</p>
              <ul class="rn-lg-size liststyle">
                        <li>
                        <a href="/terms" target="_blank"> Όροι &amp; Προϋποθέσεις</a>
                        </li>
                            <li>
                        <a href="/privacy_policy" target="_blank"> Πολιτική Απορρήτου</a>
                        </li>
                    </ul>
            </v-card> -->
          
          <v-row align="center" class="mt--100">
              <v-col lg="6" md="7" sm="12" cols="12">
                  <div class="inner text-md-right text-center mt_md--20 mt_sm--20">
                      <div class="text">
                          <p>
                              Copyright © {{ new Date().getFullYear() }} RoadCube. All Rights Reserved.
                          </p>
                      </div>
                  </div>
              </v-col>
              <v-col lg="1" md="1" sm="12" cols="12">
              </v-col>
               <v-col lg="3" md="3" sm="12" cols="12">
                  <div class="inner text-md-right text-center mt_md--20 mt_sm--20">
                      <div class="text">
                          <div class="">
                           <ul class="rn-lg-size justify-center liststyle">
                                <li>
                                <a href="/terms" target="_blank"> Όροι &amp; Προϋποθέσεις</a>
                                </li>
                                 <li>
                                <a href="/privacy_policy" target="_blank"> Πολιτική Απορρήτου</a>
                                </li>
                            </ul>
                        </div>
                      </div>
                  </div>
              </v-col>
              <v-col lg="1" md="1" sm="12" cols="12">
              </v-col>
              
              
          </v-row>
      </div>
  </div>
  <!-- End Footer Style Two  -->
</template>

<script>
  export default {
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
        socialList: [
          {
            icon: "fa-facebook-f",
            url: "https://www.facebook.com/",
          },
          {
            icon: "fa-linkedin-in",
            url: "https://www.linkedin.com/",
          },
          {
            icon: "fa-twitter",
            url: "https://twitter.com/",
          },
          {
            icon: "fa-instagram-square",
            url: "https://www.instagram.com/",
          },
          ],
          socialListText: [
              {
                  text: "Αρχική",
                  url: "/home",
              },
              {
                  text: "Εξαργύρωση πόντων",
                  url: "/home#pointRedemption",
              },
              {
                  text: "Επικοινωνία",
                  url: "/contact",
              },
          ],
          socialListHelp: [
              {
                  text: "Πηγών 33, Κηφισιά 14564",
                  class: "fa fa-map-marker",
              },
              {
                  text: "210 3006633",
                  class: "fa fa-phone",
              },
              {
                  text: "info@elinup.gr",
                  class: "fa fa-envelope",
              },
          ],
          socialListHelp1: [
              {
                  text: "Facebook",
                  url: "https://www.facebook.com/elinoilsa/",
                  class: "fab fa-facebook-f",
              },
              {
                  text: "LinkedIn",
                  url: "https://www.linkedin.com/company/elinoil-s-a-?trk=biz-companies-cym",
                  class: "fab fa-linkedin",
              },
              {
                  text: "Instagram",
                  url: "https://www.instagram.com/elinoilsa/",
                  class: "fab fa-instagram",
              },
              {
                  text: "Youtube",
                  url: "https://www.youtube.com/channel/UCSZFCEX07Kn9WBXMaCw9qmA",
                  class: "fab fa-youtube-square",
              },
          ],
      };
    },
  };
</script>

<style scoped>
   .footerStyle {
       color: #868c98;
       font-weight: 300;
   }
   .fa, .fas, .fa-linkedin, .fa-facebook-f, .fa-instagram, .fa-youtube-square {
    color: #104788;
   }
   h4 {
      color: #104788;
      font-size: 18px;
      margin-bottom: 48px;
      font-weight: 700;
   }
   a:hover {
    text-decoration: none;
   }
   p:hover {
       color: #134075;
   }
   @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .parPadding {
        padding-bottom: 0px !important;
    }
    }
    @media only screen and (min-width: 768px) and (max-width: 987px) {
    .parPadding {
        padding-bottom: 0px !important;
    }
    }
</style>
