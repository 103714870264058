<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <a :ripple="false" v-bind="attrs" v-on="on" text >Ξέχασα τον κωδικό μου;</a>
      </template>
      <v-card>
      <v-form ref="form"
        v-model="valid"
        lazy-validation>
        <h3 style="text-align: center; padding: 5%">Συμπληρώστε το κινητό σας τηλέφωνο για να σας έρθει σε sms ο κωδικός επιβεβαίωσης </h3>
        <v-card-text>
            <v-row>
            <v-col cols="1">
              </v-col>
              <!-- <v-col cols="2">
                <vue-country-code
                    v-model="country_id"
                    @onSelect="onSelect"
                    :disabled="validated != 0"
                    :preferredCountries="['gr', 'it', 'us']">
                </vue-country-code>
              </v-col> -->
              <v-col cols="9">
                <v-text-field
                  label="Κινητό*"
                  v-model="mobile" 
                  :rules="mobileRules"
                  :disabled="validated != 0"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="validated == 1">
            <v-col cols="3">
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="Κωδικός επιβεβαίωσης*"
                    v-model="code" 
                    :rules="codeRules"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="3">
              </v-col>
            </v-row>
            <v-row v-if="validated == 2">
            <v-col cols="3">
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="Κωδικός*"
                    v-model="password" 
                    :rules="passwordRules"
                    type="password" 
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="3">
              </v-col>
            </v-row>
            <v-row v-if="validated == 2">
            <v-col cols="3">
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="Επανάληψη κωδικού*"
                    v-model="password_confirmation" 
                    type="password" 
                    :rules="[passwordConfirmationRule]"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="3">
              </v-col>
            </v-row>
          <p class="error" v-if="errors">{{ errors }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="white darken-1"
            text
            @click="dialog = false"
          >
            Κλείσιμο
          </v-btn>
          <v-btn v-if="validated == 0"
            color="white darken-1"
            text
            @click="sendPassword"
          >
            Αποστολή κωδικού
          </v-btn>
          <v-btn v-if="validated == 1"
            color="white darken-1"
            text
            @click="sendPassword"
          >
            Επιβεβαίωση κωδικού
          </v-btn>
          <v-btn v-if="validated == 2"
            color="white darken-1"
            text
            @click="sendPassword"
          >
            Αλλαγή κωδικού
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
    import axios from 'axios'
    import { asyncLoading } from 'vuejs-loading-plugin'
    import Register from "@/views/Register";
    import { mapMutations, mapActions, mapGetters } from "vuex";

    export default {
       name: 'Login',
       components: {
        Register,
      },
       data() {
           return  {
             verificationCode: false,
             country_id: 1,
             validated: 0,
             password: '',
             password_confirmation: '',
             code: '',
             errors: '',
               valid: true,
               dialog: false,
               mobile: '',
               codeRules: [
                v => !!v || 'Ο κωδικός είναι υποχρεωτικός',
                v => /^\d+$/.test(v) || 'Ο κωδικός πρέπει να περιέχει μόνο αριθμούς',
              ],
              mobileRules: [
                v => !!v || 'Το κινητό τηλέφωνο είναι υποχρεωτικό',
                v => /^\d+$/.test(v) || 'Το κινητό τηλέφωνο πρέπει να περιέχει μόνο αριθμούς',
                v => (v && (""+v).length == 10) || 'Το κινητό τηλέφωνο πρέπει να αποτελείται απο 10 αριθμούς',
              ],
              passwordRules: [
                v => !!v || 'Ο κωδικός είναι υποχρεωτικός',
                v => (v && (""+v).length >= 6) || 'Το κινητό τηλέφωνο πρέπει να αποτελείται απο τουλάχιστον 6 αριθμούς',
              ],
           }
       },
       computed: {
              passwordConfirmationRule() {
                return () => (this.password === this.password_confirmation) || 'Οι κωδικοί δεν ταιριάζουν'
              },            
        },
       methods: {
           onSelect({name, iso2, dialCode}) {
              // this.country_id = dialCode;
            },
           async sendPassword(e){
             try{
                if(this.$refs.form.validate() && this.validated == 0){
                  this.$loading(true);
                      e.preventDefault();
                      this.errors = '';
                        const response = await axios.post('external/users/forgot-password', 
                        {
                            country_id: this.country_id,
                            mobile: this.mobile,
                        }).then(response => {
                            this.verificationCode = true;
                            this.validated = 1;
                            this.$loading(false);
                        }).catch(error => {
                            this.$loading(false);
                            this.$refs.form.reset();
                             this.$swal({
                                title: "Αποτυχία",
                                text: "Κάτι πήγε στραβά. Παρακαλώ ξαναπροσπαθήστε.",
                                icon: "error", //built in icons: success, warning, error, info
                                timer: 3000,
                                  buttons: {
                                    confirm: {
                                      text: "OK",
                                      value: true,
                                      visible: true,
                                      className: "",
                                      closeModal: true
                                    },
                                    cancel: {
                                      text: "Cancel",
                                      value: false,
                                      visible: true,
                                      className: "",
                                      closeModal: true,
                                    }
                                  }
                              });
                            if (error.response.data.code == 401) {
                                this.errors = "Παρακαλώ ελέγξτε τα στοιχεία σας.";
                            } 
                        });
                }else if(this.$refs.form.validate() && this.validated == 1){
                  this.$loading(true);
                      e.preventDefault();
                      this.errors = '';
                        const response = await axios.post('external/users/forgot-password/verify-code', 
                        {
                            mobile: this.mobile,
                            code: this.code,
                        }).then(response => {
                            this.validated = 2;
                            this.$loading(false);
                        }).catch(error => {
                            this.$loading(false);
                             this.$swal({
                                title: "Αποτυχία",
                                text: "Ο κωδικός επιβεβαίωσης είναι λανθασμένος.",
                                icon: "error", //built in icons: success, warning, error, info
                                timer: 3000,
                                  buttons: {
                                    confirm: {
                                      text: "OK",
                                      value: true,
                                      visible: true,
                                      className: "",
                                      closeModal: true
                                    },
                                    cancel: {
                                      text: "Cancel",
                                      value: false,
                                      visible: true,
                                      className: "",
                                      closeModal: true,
                                    }
                                  }
                              });
                        });
                } else if(this.$refs.form.validate() && this.validated == 2){
                  this.$loading(true);
                      e.preventDefault();
                      this.errors = '';
                        const response = await axios.post('external/users/forgot-password/set-password', 
                        {
                            mobile: this.mobile,
                            password: this.password,
                            password_confirmation: this.password_confirmation,
                        }).then(response => {
                            this.$refs.form.reset();
                            this.validated = 0;
                            this.$loading(false);
                            this.dialog = false;
                            this.$swal({
                                title: "Συγχαρητήρια",
                                text: "Επιτυχής αλλαγή κωδικού.",
                                icon: "success",
                                timer: 3000,
                                  buttons: {
                                    confirm: {
                                      text: "OK",
                                      value: true,
                                      visible: true,
                                      className: "",
                                      closeModal: true
                                    },
                                    cancel: {
                                      text: "Cancel",
                                      value: false,
                                      visible: true,
                                      className: "",
                                      closeModal: true,
                                    }
                                  }
                              });
                        }).catch(error => {
                            this.$loading(false);
                             this.$refs.form.reset();
                             this.$swal({
                                title: "Αποτυχία",
                                text: "Κάτι πήγε στραβά. Παρακαλώ ξαναπροσπαθήστε.",
                                icon: "error", //built in icons: success, warning, error, info
                                timer: 3000,
                                  buttons: {
                                    confirm: {
                                      text: "OK",
                                      value: true,
                                      visible: true,
                                      className: "",
                                      closeModal: true
                                    },
                                    cancel: {
                                      text: "Cancel",
                                      value: false,
                                      visible: true,
                                      className: "",
                                      closeModal: true,
                                    }
                                  }
                              });
                        });
                }else{
                  return false;
                }           
              }catch(e){
                  this.error = e.message;
                  return e;
              }
           }
       },
    };
</script>
<style scoped>

a:hover {
    color: #2496d4 !important;
  }
a {
    color: #104788 !important;
    text-decoration: none !important;
  }
</style>